<template>
    <div class="success">
        <i class="success-icon"></i>
        <p class="success-text">
            {{ popText }}
        </p>
    </div>
</template>
<script>
export default {
    props: ['popText']
}
</script>
<style lang="scss" scoped>
.success {
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 40px;
    border-radius: 13px;
    background: rgba(13, 11, 23, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.success-icon {
    width: 71px;
    height: 71px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='72' height='71' viewBox='0 0 72 71' fill='none'%3E%3Cpath d='M36.0013 65.0807C52.2721 65.0807 65.5846 51.7682 65.5846 35.4974C65.5846 19.2266 52.2721 5.91406 36.0013 5.91406C19.7305 5.91406 6.41797 19.2266 6.41797 35.4974C6.41797 51.7682 19.7305 65.0807 36.0013 65.0807Z' stroke='url(%23paint0_linear_2162_7742)' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M23.4258 35.4971L31.7979 43.8692L48.5716 27.125' stroke='url(%23paint1_linear_2162_7742)' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2162_7742' x1='7.01648' y1='39.8782' x2='55.068' y2='39.8782' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23D574DE'/%3E%3Cstop offset='1' stop-color='%238E54E9'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_2162_7742' x1='23.6801' y1='36.7369' x2='44.1021' y2='36.7369' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23D574DE'/%3E%3Cstop offset='1' stop-color='%238E54E9'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}

.success-text {
    margin-top: 24px;
    color: #FFF;
    text-align: center;
    font-family: Gilroy-Medium;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

@media (min-width: 0px) and (max-width: 1290px) {
    .success {
        padding: 20px;
    }

    .success-icon {
        width: 50px;
        height: 50px;
    }

    .success-text {
        font-size: 14px;
    }
}
</style>