<template>
     <img src="../../../public/images/loader.gif" alt="loading">
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
img {
    position: absolute;
    top: 50%;
    bottom: 0;
    right: 0;
    left: 50%;
    width: 50px;
}

@media (max-width: 680px) {
    img {
        top: 40%;
        left: 40%;
    }
}
</style>